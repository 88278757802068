import { graphql, StaticQuery } from 'gatsby'
import chunk from 'lodash.chunk'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const REVIEWS_QUERY = graphql`
  {
    allReviewsYaml {
      edges {
        node {
          id
          name
          text
          city
        }
      }
    }
  }
`

const tileStyle = {
  backgroundImage: `url("quotes.png")`,
  backgroundPosition: `95% 10%`,
  backgroundRepeat: `no-repeat`,
  backgroundSize: `3em`,
}

const renderTree = data => (
  <>
    <h1 className="title">Referencie</h1>
    <h2 className="subtitle">Prečítajte si čo o nás hovoria zákazníci</h2>
    {chunk(data.allReviewsYaml.edges, 3).map(chunks => (
      <div key={chunks[0].node.id + '_unique'} className="tile is-ancestor">
        {chunks.map(edge => (
          <div key={edge.node.id} className="tile is-parent is-4">
            <article style={tileStyle} className="tile is-child box">
              <p className="title">{edge.node.name}</p>
              <p className="subtitle">{edge.node.city}</p>
              <q>{edge.node.text}</q>
            </article>
          </div>
        ))}
      </div>
    ))}
  </>
)

const ReferenciePage = () => (
  <Layout>
    <SEO title="Referencie" description="Čo o nás povedali zákazníci" />
    <StaticQuery query={REVIEWS_QUERY} render={renderTree} />
  </Layout>
)

export default ReferenciePage
